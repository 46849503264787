import React from 'react'

export default function Gallery({ width = '28px' }) {
  return (
    <svg viewBox='0 0 28 26' className='svgIcon navIcon iconGallery' style={{ width }}>
      <title>Gallery</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-7.000000, -9.000000)'>
          <g transform='translate(7.000000, 10.000000)'>
            <rect
              stroke='#FFFFFF'
              strokeWidth='2'
              fillOpacity='0.600000024'
              fill='#000000'
              x='8'
              y='1.27897692e-13'
              width='18'
              height='14.7272727'
              rx='1'
            ></rect>
            <rect stroke='#FFFFFF' fill='#2C2C2C' x='1' y='4' width='21' height='16.3636364' rx='1'></rect>
            <g transform='translate(0.000000, 3.000000)' fill='#FFFFFF' fillRule='nonzero'>
              <path d='M22.4030055,0.596491803 C22.0282605,0.221746812 21.5779381,0.0343952641 21.0514517,0.0343952641 L1.91377596,0.0343952641 C1.38749909,0.0343952641 0.937092896,0.221704918 0.562096539,0.596491803 C0.187309654,0.971320583 0,1.42172678 0,1.94804554 L0,16.4926339 C0,17.0190783 0.187309654,17.4695264 0.562096539,17.8443133 C0.937092896,18.2191002 1.38749909,18.4064936 1.91377596,18.4064936 L21.051745,18.4064936 C21.5779381,18.4064936 22.0283862,18.2191002 22.403173,17.8443133 C22.777918,17.4695683 22.9654791,17.0191202 22.9654791,16.4926339 L22.9654791,1.94804554 C22.9654791,1.42172678 22.777918,0.971320583 22.4030055,0.596491803 Z M21.4341148,16.4926339 C21.4341148,16.5964481 21.3964517,16.6858925 21.3207067,16.7618889 C21.244878,16.8372987 21.1551403,16.8751293 21.0514517,16.8751293 L1.91377596,16.8751293 C1.81017122,16.8751293 1.72043352,16.8372987 1.64468852,16.7618889 C1.56898543,16.6859344 1.53107104,16.5964481 1.53107104,16.4926339 L1.53107104,1.94787796 C1.53107104,1.84423133 1.56885974,1.7547031 1.64468852,1.67879053 C1.72047541,1.60325501 1.81021311,1.56534062 1.91377596,1.56534062 L21.051745,1.56534062 C21.1552659,1.56534062 21.2450036,1.60325501 21.3207067,1.67879053 C21.3961585,1.7547031 21.4341148,1.84423133 21.4341148,1.94787796 L21.4341148,16.4926339 Z'></path>
              <path d='M5.35870674,7.68933151 C5.99671585,7.68933151 6.53891257,7.46616029 6.98550638,7.01956648 C7.4319745,6.57297268 7.6552714,6.03081785 7.6552714,5.39297632 C7.6552714,4.75492532 7.43184882,4.21277049 6.98550638,3.76613479 C6.53891257,3.31970856 5.99667395,3.09641166 5.35870674,3.09641166 C4.72078142,3.09641166 4.17862659,3.31970856 3.73203279,3.76613479 C3.28560656,4.2127286 3.06230965,4.75488342 3.06230965,5.39297632 C3.06230965,6.03081785 3.28548087,6.57309836 3.73203279,7.01956648 C4.17862659,7.46603461 4.72078142,7.68933151 5.35870674,7.68933151 Z'></path>
              <polygon points='8.80342805 11.1340528 6.8896102 9.22044444 3.06214208 13.0478707 3.06214208 15.3445191 19.9033789 15.3445191 19.9033789 9.98593807 14.9275865 5.01002004'></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
