import React from 'react'

export function Playlist({ width = '20px' }) {
  return (
    <svg viewBox='0 0 21 19' className='svgIcon iconPlaylist' style={{ width }}>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M1.41304348 4.92857143H19.5869565V3.80952381c0-.82842713-.6715729-1.5-1.5-1.5H2.91304348c-.82842713 0-1.5.67157287-1.5 1.5v1.11904762zM2.32608696 2.21428571H18.673913V2c0-.82842712-.6715728-1.5-1.5-1.5H3.82608696c-.82842713 0-1.5.67157288-1.5 1.5v.21428571z'
          stroke='#FFF'
        />
        <path
          d='M2 3.61904762h17c1.1045695 0 2 .8954305 2 2V17c0 1.1045695-.8954305 2-2 2H2c-1.1045695 0-2-.8954305-2-2V5.61904762c0-1.1045695.8954305-2 2-2zm7.44521096 3.55586982c-.16736256-.11415244-.36544905-.1747808-.56803275-.17385787-.55227902.00251608-.99794994.45226637-.99543386 1.00454539l.02853443 6.26330594c.00090357.1983346.06076464.3919211.17197261.5561472.30966771.4573012.93141882.5769817 1.38871999.267314l4.59366112-3.1106601c.1043559-.070666.1944132-.1604217.2654288-.26454.3111997-.45626.1936052-1.078409-.2626548-1.3896088L9.44521096 7.17491744z'
          fill='#FFF'
        />
      </g>
    </svg>
  )
}

export default Playlist
