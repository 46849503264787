import React from 'react'
import LazyLoad from 'react-lazyload'

const LazyLoadSSR = ({ children, immediate = false, ...props }) => {
  if (immediate) {
    return children
  }
  return <LazyLoad {...props}>{children}</LazyLoad>
}

export default LazyLoadSSR
