import React, { memo, useMemo } from 'react'
import Link from 'next/link'
import { sendEvent } from 'lib/ga'
import MediaQuery from 'components/MediaQuery'
import LazyLoad from 'components/LazyLoad'
import { truncateString, storyLink, playlistLink, getTitleExtras, THEME } from 'utils'
import CommentsIcon from 'icons/Comments'
import StoryCardIndicator from 'components/StoryCardIndicator'
import StoryCardMeta from 'components/StoryCardMeta'
import Thumbnail from 'components/Thumbnail'
import { track } from 'lib/whoami'
import { storyTypeCDPMap } from 'utils'

const CHARACTER_COUNT = 60

function StoryCard({ story, index = 0, searchDropdown = false, feedType = 'type' }) {
  const linkProps = feedType === 'playlists' ? playlistLink(story) : storyLink(story)

  const titleExtras = useMemo(() => getTitleExtras(story), [story])

  const handleStoryCardCDPEvent = () => {
    if (!story) return
    sendEvent('Story Card', 'Click', { label: story?.type, value: story?.id })
    track('ContentClicked', {
      content: {
        src: story?.url,
        type: storyTypeCDPMap[story?.type],
        barstoolBrandID: story?.brand_id ? String(story?.brand_id) : undefined,
        barstoolTalentID: String(story?.author?.id),
        barstoolPodcastID: story?.post_type_meta?.podcast?.id ? String(story?.post_type_meta?.podcast?.id) : undefined,
        barstoolPostID: String(story?.id),
        imageSrc: story?.thumbnail?.featured,
        componentName: 'StoryCard'
      }
    })
  }
  return (
    <>
      <article
        onClick={handleStoryCardCDPEvent}
        className={`storyCard ${searchDropdown ? 'storyCard--searchDropdown' : ''} storyCard--${feedType}`}
      >
        <Link prefetch={false} {...linkProps} legacyBehavior>
          <a className='storyCard__image'>
            <Thumbnail
              src={story.thumbnail.desktop}
              alt={story.title}
              aspectRatio={feedType === 'playlists' ? '16:9,smart' : '4:3,smart'}
            />
            {!searchDropdown && <StoryCardIndicator story={story} playlist={feedType === 'playlists'} />}
          </a>
        </Link>
        <div className='storyCard__content'>
          <div className='storyCard__header'>
            {titleExtras ? <span className='showTitle'>{titleExtras}</span> : null}
            <Link {...linkProps} legacyBehavior>
              <a className='storyCard__title'>
                <MediaQuery minWidth={THEME.BREAKPOINTS.XS}>
                  {searchDropdown ? truncateString(story.title, 40) : story.title}
                </MediaQuery>
                <MediaQuery maxWidth={THEME.BREAKPOINTS.XS - 1}>
                  {truncateString(story.title, CHARACTER_COUNT)}
                </MediaQuery>
              </a>
            </Link>
          </div>
          <div className='storyCard__footer'>
            <StoryCardMeta story={story} searchDropdown={searchDropdown} type={feedType} />
            {story.comment_status_open || story.comment_count ? (
              <div
                className={`storyCard__commentCount ${!story.comment_count ? 'storyCard__commentCount--hidden' : ''}`}
              >
                <span>{story.comment_count}</span>
                <CommentsIcon color='#333' width='auto' height='20px' />
              </div>
            ) : null}
          </div>
        </div>
      </article>
      <style jsx>{`
        .storyCard {
          display: flex;
        }
        .storyCard {
          padding: 18px;
          background-color: #ffffff;
          border-bottom: 2px solid #f1f1f1;
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM}px) {
          .storyCard {
            padding: 12px 10px;
          }
        }
        .storyCard__image {
          display: block;
          min-width: 142px;
          min-height: ${feedType === 'playlists' ? 'none' : '105px'};
          flex-basis: 142px;
          position: relative;
          overflow: hidden;
          border: 1px solid #eeeeee;
          background: url(/static/images/placeholder.png) no-repeat center #f5f5f5;
          background-size: 5rem;
          max-height: 210px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .storyCard__image {
            flex-basis: 160px;
            width: 160px;
            min-width: 160px;
            min-height: ${feedType === 'playlists' ? 'none' : '118.5px'};
          }
        }
        .storyCard__image img {
          width: 100%;
          height: auto;
        }
        .storyCard__image video {
          width: 100%;
          height: auto;
        }
        .storyCard__content {
          flex-grow: 1;
          padding-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .storyCard--playlists .storyCard__content {
          justify-content: flex-start;
        }
        .showTitle {
          display: none;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .showTitle {
            display: flex;
            font-size: 12px;
            letter-spacing: 0.8px;
            font-weight: 600;
            text-transform: uppercase;
            color: #999;
            line-height: 1.3;
          }
        }
        .storyCard__title {
          display: block;
          color: ${THEME.COLORS.TEXTCOLOR};
          font-size: 14px;
          font-weight: bold;
          letter-spacing: -0.1px;
          line-height: 18px;
          margin: 0 0 16px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .storyCard__title {
            font-size: 16px;
          }
        }
        .storyCard--playlists .storyCard__title {
          margin-bottom: 0;
        }
        .storyCard__footer {
          display: flex;
          justify-content: space-between;
        }
        .storyCard__meta {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          min-height: 28px;
        }
        .storyCard__avatar {
          display: none;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .storyCard__byline {
          display: flex;
          flex-direction: column;
        }
        .storyCard__author {
          color: ${THEME.COLORS.TEXTCOLOR};
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.1px;
          width: 100%;
          margin: 0 0 4px;
        }
        .storyCard__timestamp {
          color: ${THEME.COLORS.GRAY};
          font-size: 12px;
          font-style: italic;
          letter-spacing: 0.1px;
        }
        .storyCard__commentCount {
          display: flex;
          align-items: center;
          margin-left: 10px;
          font-weight: 600;
          color: ${THEME.COLORS.TEXTCOLOR};
        }
        .storyCard__commentCount--hidden {
          display: none !important;
        }
        .storyCard__commentCount span {
          font-size: 12px;
          letter-spacing: 0.1px;
          padding: 5px;
        }
        .storyCard--searchDropdown {
          padding: 6px;
        }
        .storyCard--searchDropdown .storyCard__image {
          min-width: 100px;
          flex-basis: 100px;
        }
        .storyCard--searchDropdown .storyCard__content {
          padding-left: 8px !important;
        }
        .storyCard--searchDropdown .storyCard__title {
          font-size: 12px !important;
          line-height: 16px;
        }
        .storyCard--searchDropdown .storyCard__avatar,
        .storyCard--searchDropdown .storyCard__commentCount {
          display: none !important;
        }
        .storyCard--searchDropdown .storyCard__author,
        .storyCard--searchDropdown .storyCard__timestamp {
          font-size: 10px !important;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .storyCard__content {
            padding-left: 24px;
          }
          .storyCard__author {
            width: auto;
            margin: 0 10px 0 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .storyCard__title {
            font-size: 24px;
            line-height: 30px;
          }
          .storyCard__image {
            min-width: 280px;
            min-height: ${feedType === 'playlists' ? 'none' : '208.5px'};
            flex-basis: 280px;
          }
          .storyCard__meta {
            flex-wrap: nowrap;
          }
          .storyCard__avatar {
            display: block;
          }
          .storyCard__author {
            margin-right: 20px;
          }
          .storyCard__commentCount {
            margin-left: 20px;
          }
        }
      `}</style>
    </>
  )
}

export default memo(StoryCard)
