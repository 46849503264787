import React from 'react'
import { THEME } from 'utils'

export default function CommentsIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 28 18' className='svgIcon iconComments' style={{ width, height }}>
      <title>Comments</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-779.000000, -207.000000)' stroke={color} strokeWidth='1.5'>
          <g transform='translate(768.000000, 209.000000)'>
            <path
              className='stroke'
              d='M19.6896292,13.4479 L15.269104,15.8822087 L15.269104,11.7791726 C13.3775287,10.4433581 12.25,8.50301563 12.25,6.39318572 C12.25,2.40559121 16.2237406,-0.75 21.050944,-0.75 C25.8781474,-0.75 29.851888,2.40559121 29.851888,6.39318572 C29.851888,10.3810891 25.8783403,13.5363714 21.050944,13.5363714 C20.6000617,13.5363714 20.1472293,13.5062713 19.6896292,13.4479 Z'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
