import React from 'react'
import Link from 'next/link'
import { THEME, wordsOrDate, authorLink } from 'utils'

export const StoryCardMeta = ({ story, searchDropdown, type = 'story' }) => {
  const timestamp = story.date || story.updated_at
  return (
    <div className={`storyCard__meta ${searchDropdown ? 'storyCard__meta--searchDropdown' : ''}`}>
      {story.author && story.author.avatar && (
        <img className='storyCard__avatar' src={story.author.avatar} alt={story.author.name} />
      )}
      <div className='storyCard__byline'>
        {story.author && <div className='storyCard__author'>{story.author.name}</div>}
        <div className='storyCard__timestamp'>
          {story.pinned ? (
            <>
              <span>📌 </span>Featured
            </>
          ) : (
            <>
              {type === 'playlists' ? 'Updated ' : ''}
              {wordsOrDate(timestamp)}
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        .storyCard__meta {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          min-height: 28px;
        }
        .storyCard__avatar {
          display: none;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .storyCard__byline {
          display: flex;
          flex-direction: column;
        }
        .storyCard__author {
          color: ${THEME.COLORS.TEXTCOLOR};
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.1px;
          width: 100%;
          margin: 0px;
        }
        .storyCard__timestamp {
          color: ${THEME.COLORS.GRAY};
          font-size: 12px;
          font-style: italic;
          letter-spacing: 0.1px;
        }
        .storyCard__timestamp span {
          font-style: normal;
        }
        .storyCard__meta--searchDropdown .storyCard__avatar {
          display: none !important;
        }
        .storyCard__meta--searchDropdown .storyCard__author,
        .storyCard__meta--searchDropdown .storyCard__timestamp {
          font-size: 10px !important;
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .storyCard__meta {
            flex-wrap: nowrap;
          }
          .storyCard__avatar {
            display: block;
          }
          .storyCard__commentCount {
            margin-left: 20px;
          }
        }
      `}</style>
    </div>
  )
}

// wrap StoryCardMeta in link to author bio page
const StoryCardMetaContainer = ({ story, ...props }) => {
  const authorIsActive = story?.author?.is_active ?? false
  if (authorIsActive) {
    return (
      <Link {...authorLink(story.author)} legacyBehavior>
        <a>
          <StoryCardMeta story={story} {...props} />
        </a>
      </Link>
    )
  }
  return <StoryCardMeta story={story} {...props} />
}

export default StoryCardMetaContainer
