import React from 'react'

export default function LiveNow({ width = '20px', fill = 'white' }) {
  return (
    <svg viewBox='0 0 292 400' className='svgIcon liveIcon' style={{ width, fill }}>
      <title>Live Now</title>
      <path d='M226.3,216.4 L196.5,211.9 C205,156.2 163.8,103.5 104.8,94.6 L109.3,64.8 C184.8,76.2 237.2,144.2 226.3,216.4 Z' />
      <path d='M288.9,219.9 L259.1,215.4 C265.6,172.9 254.2,130.3 227.2,95.4 C200,60.3 160.1,37.2 114.9,30.3 L119.4,0.5 C172.2,8.5 219,35.6 250.9,76.9 C283.1,118.4 296.6,169.1 288.9,219.9 Z' />
      <path d='M136,181 C138.9,179.3 141.4,177.7 143.4,176 C152.7,168.1 150.6,150.9 139.8,146 C133.2,143 122.3,141.4 108.9,141.4 C97.7,141.4 84.7,142.5 71,144.8 C40.8,149.9 15.7,159.5 5.8,168.7 C-3.1,176.9 -0.3,194 10.8,198.4 C12.9,199.2 15.5,200 18.3,200.5 L6.9,372.8 C6.7,375.6 7.7,378.2 9.6,380.2 C11.7,382.4 15,383.7 18.4,383.9 C18.6,383.9 18.8,383.9 18.9,383.9 C21.8,383.9 24.5,383 26.5,381.4 C28.6,379.7 29.9,377.2 30.1,374.4 L33.8,318.5 C42.7,321.7 54.6,322.4 63.3,322.4 C66.4,322.4 69.6,322.3 72.9,322.2 C77.1,322 81.4,321.6 85.7,321.2 L96.5,390.6 C96.9,393.3 98.4,395.7 100.8,397.2 C102.7,398.4 105.1,399.1 107.6,399.1 C108.5,399.1 109.3,399 110.2,398.9 C113.3,398.3 115.9,396.9 117.6,394.7 C119.3,392.5 120,389.9 119.5,387.1 L108.7,317.7 C115.4,316.3 122,314.7 128,312.9 C140.6,309.1 150.6,304.5 157,299.7 C157.2,299.6 157.3,299.4 157.5,299.3 L178.1,351.5 C179.2,354.2 181.1,356.1 183.7,357.1 C184.9,357.6 186.2,357.8 187.6,357.8 C189.1,357.8 190.6,357.5 192.1,357 C195.4,355.8 198,353.6 199.4,350.8 C200.6,348.3 200.8,345.5 199.7,343 L136,181 Z M63.8,307.4 L63.3,307.4 C48.1,307.4 39.5,305.1 36.5,303.5 C37.9,302 41.6,299.3 49,296 C55.3,293.3 65.4,289.6 80.3,286.6 L83.4,306.3 C76.5,307 69.9,307.4 63.8,307.4 Z M36,285.7 L41.5,202.6 C49.3,202.6 57.9,202 67,200.9 L78,271.7 C71.2,273.1 64.5,274.8 58.4,276.6 C49.5,279.3 42,282.4 36,285.7 Z M79.1,188.4 C69.2,190.2 59.8,191 51.1,191 C31.1,191 15.8,186.5 11.4,178.6 C18.3,183.7 31.6,186.6 48,186.6 C57.5,186.6 68,185.7 79,183.7 C106.3,178.8 128.7,168.8 138.1,158.5 C133.3,170.4 109.3,182.9 79.1,188.4 Z M90.1,197 C99,195.1 107.3,192.8 114.7,190.3 L145.6,268.7 C138.2,267.2 129.9,266.8 123.5,266.8 C116.5,266.8 108.9,267.2 101.2,268.1 L90.1,197 Z M137,293.6 C130.8,296.3 120.9,299.8 106.4,302.7 L103.3,283 C110.4,282.2 117.1,281.8 123.4,281.8 C138.6,281.8 147.2,284.1 150.2,285.7 C148.8,287.3 145,290.2 137,293.6 Z' />
    </svg>
  )
}
